import React from "react";
import "../styles/Bio.css";
import { CgClose } from "react-icons/cg";

const Bio = (props) => {
  return (
    <div className="bio">
      <CgClose
        className="bio-close"
        size={70}
        onClick={() => {
          props.setShowBio(false);
        }}
      />
      <div className="bio-title">{props.title}</div>
      <div className="bio-text">{props.text}</div>
      <div className="bio-table">{props.table}</div>
      <span className="bio-footer">{props.footer}</span>
    </div>
  );
};

export default Bio;
