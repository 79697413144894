import React from "react";
import ReactDOM from "react-dom/client";
import Carousel from "./components/Carousel";
import "./index.css";

function App() {
  return (
    <div className="app">
      <Carousel />
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
